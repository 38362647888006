<!--portfolio start-->
<section class="resume portfolio-section zoom-gallery filter-section shadow-sm">
        <div class="container">
          <div class="row">
              <div class="col-md-8 offset-md-2">
                  <div class="title title2">
                      <!-- <h6 class="font-primary borders main-text text-uppercase"><span>work</span></h6> -->
                      <div class="sub-title">
                          <div class="">
                              <h2 class="title-text">my latest work</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    <app-portfolio-gallery class="p-4col-width" class="p-4col-width"></app-portfolio-gallery>
    </div>
    </section>
<!--portfolio end-->