import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-social-media',
  templateUrl: './resume-social-media.component.html',
  styleUrls: ['./resume-social-media.component.scss']
})
export class ResumeSocialMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  socialMedias = [
    {
      name: 'FB Gaming',
      url:'https://fb.gg/gustindonzales',
    },
    {
      name: 'Twitter',
      url:"https://www.twitter.com/gustindonzales",
    },
    {
      name: 'Instagram',
      url:"https://www.instagram.com/gustindonzales/",
    },
    {
      name: 'Tik Tok',
      url:"https://www.tiktok.com/@gustindonzales",
    },
    {
      name: 'LinkedIn',
      url:"https://www.linkedin.com/in/dustin-gonzales-042940126",
    },
  ]

  goToLink(url: string): void {
    window.open(url, "_blank");
  }

}
