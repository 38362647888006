<!-- <app-resume-nav></app-resume-nav> -->
<app-resume-header></app-resume-header>
<app-resume-about></app-resume-about>
<!-- <app-resume-services></app-resume-services> -->
<app-resume-social-media></app-resume-social-media>
<app-resume-portfolio></app-resume-portfolio>

<!-- <app-resume-schedule></app-resume-schedule>
<app-resume-pricing></app-resume-pricing>
<app-resume-subscribe></app-resume-subscribe> -->
<app-resume-footer></app-resume-footer>