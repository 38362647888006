<!--header start-->
<section class="resume header" id="header">
  <div class="header7-content">
      <div class="resume bg bg-pink"  [ngStyle]="{'background-image': 'url(assets/images/resume/h7-bg-text.png)'}">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6 center-text">
                    <img src="assets/images/resume/dustin_crop.jpg" alt="" class="me img-fluid rounded-circle shadow-sm">
                    <!-- <img src="assets/images/resume/h7.png" alt="" class="img-fluid set-abs bottom-0 right-0"> -->
                  </div>
                  <div class="col-lg-6 col-md-6 offset-lg-1">
                      <div class="center-text">
                          <div>
                              <div class="header-text">
                                  <h1 class="text-shadow"><span class="bold-text">Hi, I’m</span><br> Dustin Gonzales</h1>
                              </div>
                              <div class="header-sub-text">
                                  <p class="font-secondary font-large">I am a passionate web developer and fitness enthusiast.</p>
                              </div>
                              <div class="link-horizontal">
                                  <ul>
                                      <li><button type="button" class="btn btn-default primary-btn back-white" [scrollTo]="'#about'" [scrollDuration]="500">know more</button></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--header end-->