<!--services start-->
<section class="resume services bg-pink">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <!-- <h6 class="font-primary borders main-text text-uppercase"><span>details</span></h6> -->
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">Social Media</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center mb-4" *ngFor="let socialMedia of socialMedias">
                <button type="button" class="btn btn-default primary-btn back-white" (click)="goToLink(socialMedia.url)">{{socialMedia.name}}</button>
            </div>
        </div>
    </div>
</section>
<!--services start-->