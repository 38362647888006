import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-about',
  templateUrl: './resume-about.component.html',
  styleUrls: ['./resume-about.component.scss']
})
export class ResumeAboutComponent implements OnInit {
   private _dateNow: Date = new Date();
   about = [
    {
      name:'Node.js',
      timer: 90,
      exp:`${this._differenceInYears(new Date(2015,12,1), this._dateNow)} Years Experince`
    },
    {
      name:'Angular',
      timer: 95,
      exp:`${this._differenceInYears(new Date(2015,12,1), this._dateNow)} Years Experince`
    },
    {
      name:'html & css',
      timer: 80,
      exp:`${this._differenceInYears(new Date(2012,1,1), this._dateNow)} Years Experince`
    },
    {
      name:'Relational Database',
      timer: 85,
      exp:`${this._differenceInYears(new Date(2012,1,1), this._dateNow)} Years Experince`
    }
  ];
  
  constructor() { }

  ngOnInit() {
  }

  

  private _differenceInYears(dt2, dt1): number {
   let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
   return Math.abs(Math.round(diff/365.25));  
  }

  isGTET(n1, n2): boolean {
    return n1 >= n2;
  }

  isLT(n1, n2): boolean {
    return n1 < n2;
  }
}
